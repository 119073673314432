import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material";


import { AgreementTermsComponent } from '../agreement-terms/agreement-terms.component';
import { AgreementTermsSulAmericaComponent } from '../agreement-terms-sulamerica/agreement-terms-sulamerica.component';
import { DataService } from '../shared/services/dataservice';



@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})

export class AgreementComponent implements OnInit {

  public patientData: string = "";
  public patientToken: string = "";
  public partnerToken: string = "";
  public req: any = {};

  public isValid: boolean = false;
  public returnedValue: any[];

  public logo: string = "";
  public show: boolean = false;
  public homeSulAmerica: boolean = false;

  public configTermos: any[];

  // public validKeys: string[] = ["7b2dbdd54d3ab62a11c435a96d902dba","2d71af3f80745b6e6eff9fbab91a348c", "a8044577a2d2f0ed02a57f181993b9a2", 
  //               "a5b95592427fd9dc88a4b0f2d9410411", "cf8ba9baa1a663fa3ace22a347a4b4dd", "1c17c193f3f15c9b8a3f4f453b4ce0fa",
  //               "9b859eaed6601a01b273bf27ebef2773","ef984a2c915374d0eef460f76e17ce2d","a3ac6af539a3fe35c7761461b5e3f3a2",
  //               "fba757d73c1917d260cf61059fd3556e"];

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router
  ) {  
  }

  ngOnInit() {

    //console.log('ok');
    this.validateParams(this.route.snapshot.params);

    this.configTermos = [{
      "politicaPrivacidade":true,
      "termos":true,
      "autorizacaoUso":true,
      "politicaSaude":true,
    }];

    this.loadHome();

    //console.log(this.route);

  }

  validateParams = (params) => {

    //console.log(params);

    if(params.key && params.token){
      
      let newKey = params.key;
      let keyComplete = newKey.split("|");

      this.patientData = keyComplete[1];
      this.patientToken = params.token;
      this.partnerToken = keyComplete[0];

      this.req = this.generateReq();

      this.manipulateTemplate(this.partnerToken);

      /*let keyComplete = JSON.parse(params.key);

      this.patientData = keyComplete.criptoContent;
      this.patientToken = params.token;
  
      //console.log("patientKey = " + this.patientData);
      //console.log("patientToken = " + this.patientToken);
  
      this.manipulateTemplate(keyComplete.cliente);*/
    }

  }

  generateReq = () => {
    return {
      "ip": "167.250.29.235",
      "userAgent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.142 Safari/537.36"
    };
  }

  manipulateTemplate = (cliente) => {
    let logos = {
      'ac13988d5fe9c8b959c8cf54fa23a3b6': 'logo-apm.svg',//APM
      '22c01d5f9a3ef307e4c169501be3cf71': 'logo-qualicorp.png',//QualiCorp
      'f00eaccc09544aa4cba7cfccd92d696b': 'sulamerica.jpg',//SulAmerica
      '615d3f581055395d67a134e91d5c9ac0': 'sulamerica.jpg',//SulAmerica
      '0298e3cea3d2f28cd21cd41bdb054b6c': 'totvs.png',//Totvs
      '6c0d9548655d3047436ef4a00cf2218b': 'clinicweb.png',//Clinicweb
      '56da8bc8956bf0ff4d3f82ffa591a83f': 'prontmed.png',//Prontmed
    };

    if(cliente != null && cliente != "" && logos.hasOwnProperty(cliente)){
      this.logo = logos[cliente];
      this.show = true;
    } else {
      this.show = false;
    }

    if(cliente == 'f00eaccc09544aa4cba7cfccd92d696b' || cliente == '615d3f581055395d67a134e91d5c9ac0'){
      this.homeSulAmerica = true;
    } else {
      this.homeSulAmerica = false;
    }
    
  }


  openDialog(type: string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      termType: type
    };
    
    if(this.partnerToken == 'f00eaccc09544aa4cba7cfccd92d696b' || this.partnerToken == '615d3f581055395d67a134e91d5c9ac0'){
      this.dialog.open(AgreementTermsSulAmericaComponent, dialogConfig);
    } else {
      this.dialog.open(AgreementTermsComponent, dialogConfig);
    }
    

  }

  loadHome() {
    this.dataService
      .home<any[]>(this.patientData, this.patientToken, this.partnerToken)
      //.sendMail()
      .subscribe(data => console.log(JSON.stringify(data)),
        error => () => {
          // this.toasterService.pop('error', 'Damn', 'Something went wrong...');
          console.log("Ocorreu um erro ao enviar o consentimento!");
          //this.showFailMessage();
        },
        () => {
          //this.showSucessMessage();
          //console.log("this.returnedValue = " + this.returnedValue.toString());
        });
  }

  accept() {
    
    this.dataService
      .accept<any[]>(this.patientData, this.patientToken, this.partnerToken, this.req, "yes")
      //.sendMail()
      .subscribe(data => console.log(JSON.stringify(data)),
        error => () => {
          // this.toasterService.pop('error', 'Damn', 'Something went wrong...');
          console.log("Ocorreu um erro ao enviar o consentimento!");
          this.showFailMessage();
        },
        () => {
          this.showSucessMessage();
          //console.log("this.returnedValue = " + this.returnedValue.toString());
        });
  }

  

  cancel() {

    this.dataService
      .accept<any[]>(this.patientData, this.patientToken, this.partnerToken, this.req, "no")
      //.sendMail()
      .subscribe(data => console.log(JSON.stringify(data)),
        error => () => {
          // this.toasterService.pop('error', 'Damn', 'Something went wrong...');
          console.log("Ocorreu um erro ao enviar o consentimento!");
          //this.showFailMessage();
        },
        () => {
          //this.showSucessMessage();
          //console.log("this.returnedValue = " + this.returnedValue.toString());
          document.getElementById("main").style.backgroundColor = '#EFF8FB';

          //document.getElementById("main").style.height = window.innerHeight -100 + "px";

          document.getElementById("main").style.maxHeight = "500px";
          document.getElementById("main").innerHTML = "<p style=\"text-align: center; vertical-align: middle; justify-content: center; align-items: center; \">" +
            "Agradecemos a atenção.<br>" +
            "Esperamos que reconsidere a decisão no futuro, temos certeza que esta é uma oportunidade de contribuir significavamente com a qualidade de saúde do Brasil." +
            "</p>";

        });

  }

  showSucessMessage() {
    var msg = "Parabéns por autorizar o compartilhamento dos seus dados.<br>" +
      "Isso contribuirá significavamente com a qualidade de saúde do Brasil.<br>" +
      "Dentro de alguns dias você terá dados disponivéis para consulta";//, enquanto isso acesse <a href=\"http://www.datamea.com.br\">www.datamea.com.br</a>.";

    this.showMessage(msg);
  }

  showFailMessage() {
    var msg = "Ocoreu um problema ao enviar a sua resposta.<br>" +
      "Tente fechar esta pagina e realizar o processo de consentimento novamente.<br>";

    this.showMessage(msg);
  }

  showMessage(msg: string) {
    document.getElementById("main").style.backgroundColor = '#EFF8FB';
    document.getElementById("main").style.maxHeight = "500px";
    document.getElementById("main").style.textAlign = "center";
    document.getElementById("main").innerHTML = "<p style=\"text-align: center; vertical-align: middle; justify-content: center; align-items: center; \">" +
      msg +
      "</p>";
  }

}
