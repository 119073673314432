import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { AgreementComponent } from './agreement/agreement.component';




const appRoutes: Routes = [
    // {
    //   path: '',
    //   component: AgreementComponent,
    // },
    {
      path: '',
      component: AgreementComponent,
      data: { title: 'DataOpera - Consentimento' }
    },
    {
      path: ':key/:token',
      component: AgreementComponent,
      data: { title: 'DataOpera - Consentimento' }
    },
    /*{
      path: 'p/:key/:token',
      component: AgreementComponent,
      data: { title: 'DataOpera - Consentimento' }
    }*/
    // ,        
    // { path: '',
    //   redirectTo: 'p/:key',
    //   pathMatch: 'full' 
    // }
  ];
  
  @NgModule({
    imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
    exports: [RouterModule],
    // providers: [DataService]
  })
  export class AppRoutingModule { }