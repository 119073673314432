import { Injectable } from '@angular/core';

@Injectable()
export class Configuration {
    //public Server = 'https://emr-prod.ezorder.com.br/';
    //public ApiUrl = 'v1/';
    //public Server = 'https://tune.dataopera.com.br/';
    //public Server = 'http://localhost:3006';
    public Server = 'https://agreementapi.dataopera.com.br';
    public ApiUrl = '/';
    public ServerWithApiUrl = this.Server + this.ApiUrl;
}