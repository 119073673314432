import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomInterceptor, DataService } from './shared/services/dataservice';
import { HttpClientModule } from '@angular/common/http';



import { AppComponent } from './app.component';
import { Configuration } from './app.constants';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';

import 'hammerjs';



import {
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatListModule,
  MatDialogModule
} from "@angular/material";

import { AppRoutingModule } from './app-routing.module';

import { AgreementComponent } from './agreement/agreement.component';
import { AgreementTermsComponent } from './agreement-terms/agreement-terms.component';
import { AgreementTermsSulAmericaComponent } from './agreement-terms-sulamerica/agreement-terms-sulamerica.component';

import { SharedModule } from './shared/shared.module';


@NgModule({
  declarations: [
    AppComponent,

    AgreementComponent,
    AgreementTermsComponent,
    AgreementTermsSulAmericaComponent,
  ],
  imports: [
    AppRoutingModule,
    //RouterModule.forRoot(appRoutes, {useHash: true }),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatListModule,
    MatDialogModule,
    SharedModule
  ], 
  providers: [
    Configuration,
    SharedModule
  ],  
  bootstrap: [AppComponent],
  entryComponents: [AgreementTermsComponent,AgreementTermsSulAmericaComponent]
})
export class AppModule { }
