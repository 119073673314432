import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { DataService, CustomInterceptor} from './services/dataservice';

@NgModule({
    imports: [CommonModule, RouterModule],
    providers: [DataService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomInterceptor,
            multi: true,
        }
    ],
})

export class SharedModule { }
